const config = {
    // DEBUG
    // URL:'http://localhost:8081/api/auth/'
    // URL:'https://localhost:8443/api/auth/'
    
    // URL:'http://198.244.188.246:8080/api/auth/'
    // PRODUCCION
    URL:'https://apitrademasterbots.com:8443/api/auth/'
}

module.exports = config