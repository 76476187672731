<template>
  <v-container class="bg-surface-variant">
    <div class="d-flex flex-column mb-6 bg-surface-variant">
      <v-row class="text-center">
        <v-col cols="12">
          <!-- <v-img :src="require('../../assets/logo_vue.svg')" class="my-3" contain height="200" /> -->
          <v-img :src="require('../../assets/logo.jpg')" class="my-3" contain height="200" />
        </v-col>
        <br>
        <v-row class="text-center">
          <v-col cols="12">
            <br>
            <v-card elevation="2" outlined shaped>
              <v-row class="text-center">
                <v-col cols="12" class="mb-4">
                  <br>
                  <h1 class="display-2 font-weight-bold mb-3">
                    {{ username }}
                  </h1>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <div v-if="!isMobile()">
                <v-row class="text-center">
                  <v-col cols="6" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <br>
                      <h3>Tipo de suscripción: {{ userType }}</h3>
                      <p class="subheading font-weight-regular">
                        <br>Bots suscripción: {{ botsType }}
                        <br>Bots creados: {{ botsCreated }}
                        <br>Fin suscripción: {{ endSubs }}
                      </p>
                      <v-btn color="success" @click="pagar">
                        PAGAR
                      </v-btn>
                    </v-card>
                  </v-col>
                  <v-col cols="6" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <Bar id="my-chart-id" v-if="loaded" :data="chartData" :style="myStyles" />
                    </v-card>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <v-row class="text-center">
                  <v-col cols="12" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <br>
                      <h3>Tipo de suscripción: {{ userType }}</h3>
                      <p class="subheading font-weight-regular">
                        <br>Bots suscripción: {{ botsType }}
                        <br>Bots creados: {{ botsCreated }}
                        <br>Fin suscripción: {{ endSubs }}
                      </p>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="text-center">
                  <v-col cols="12" class="mb-4">
                    <v-card elevation="2" outlined shaped>
                      <Bar id="my-chart-id" v-if="loaded" :data="chartData" :style="myStyles" />
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import User from '../../models/user';
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'HomeComponent',
  components: {
    Bar
  },
  data() {
    return {
      title: 'HOME',
      user: new User('', '', ''),
      content: '',
      username: '',
      userType: '',
      botsType: '',
      botsCreated: '',
      endSubs: '',
      totalInvertido: '',
      totalBeneficios: '',
      totalComisiones: '',
      submitted: false,
      successful: false,
      message: '',
      valid: '',
      isShow: false,
      msgError: '',
      types: "success",
      dialog: false,
      loaded: false,
      chartData: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril',
          'Mayo', 'Junio', 'Julio', 'Agosto',
          'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [{
          label: 'Ganancias Mensuales',
          data: [],
          backgroundColor: '#00001c',
        }]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  created() {
    // if (this.$store.user === "") {
    //   this.$store.dispatch("auth/logout");
    //   this.$router.push("/");
    // }
    // this.width = window.innerWidth
  },
  computed: {
    currentUser() {
      return this.user;
    },
    myStyles() {
      return {
        height: '${/* mutable height */}px',
        position: 'relative'
      }
    }
  },
  mounted() {
    this.loaded = false
    UserService.getHomeInfo(this.$store.state.auth.user.email).then(
      response => {
        this.username = response.data.userName;
        this.userType = response.data.userType;
        this.botsType = response.data.botsType;
        this.botsCreated = response.data.botsCreated;
        this.endSubs = response.data.end_time;
        this.totalInvertido = response.data.totalInvertido;
        this.totalBeneficios = response.data.beneficios;
        this.totalComisiones = response.data.comisiones;
        for (let index = 0; index < this.totalBeneficios.length; index++) {
          this.chartData.datasets[0].data.push(this.totalBeneficios[index].beneficios)
        }
        this.loaded = true
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        EventBus.dispatch("logout");
      }
    );
  },
  methods: {
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    pagar() {
      UserService.getLinkPay(this.$store.state.auth.user.email).then(
            response => {
                console.log(response)
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    }
  }
}
</script>
