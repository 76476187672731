import Vue from 'vue'
import VueRouter from 'vue-router'
// PUBLIC
import IndexView from '../views/public/IndexView.vue'
import LoginView from '../views/public/LoginView.vue'
import RegisterView from '../views/public/RegisterView.vue'
import FormNewUserView from '../views/public/FormNewUserView.vue'
import VerifyView from '../views/public/VerifyView.vue'
import TermsView from '../views/public/TermsView.vue'
// USER
import HomeView from '../views/user/HomeView.vue'
import ProfileView from '../views/user/ProfileView.vue'
import ClientView from '../views/user/ClientView.vue'
import ClientSaveView from '../views/user/ClientSaveView.vue'
import WalletsView from '../views/user/WalletsView.vue'
import BotView from '../views/user/BotView.vue'
import BotSaveView from '../views/user/BotSaveView.vue'
import OrdersView from '../views/user/OrdersView.vue'
import SuscripcionView from '../views/user/SuscripcionView.vue'
import SuscripcionMenuView from '../views/user/SuscripcionMenuView.vue'
import FormPayView from '../views/user/FormPayView.vue'
import SuccessView from '../views/user/SuccessView.vue'
import TicketView from '../views/user/TicketView.vue'
import TicketSaveView from '../views/user/TicketSaveView.vue'
// ADMIN
import AdminHomeView from '../views/admin/AdminHomeView.vue'
import AdminUsersView from '../views/admin/AdminUsersView.vue'
import AdminUserView from '../views/admin/AdminUserView.vue'
import AdminUserNewView from '../views/admin/AdminUserNewView.vue'
import AdminBotsView from '../views/admin/AdminBotsView.vue'
import AdminTradesView from '../views/admin/AdminTradesView.vue'
import AdminSubsView from '../views/admin/AdminSubsView.vue'
import AdminSubView from '../views/admin/AdminSubView.vue'
import AdminComercialesView from '../views/admin/AdminComercialesView.vue'
import AdminComercialSaveView from '../views/admin/AdminComercialSaveView.vue'
import AdminPagosView from '@/views/admin/AdminPagosView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: IndexView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register/:referido?',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/form_new_user/:referido?',
    name: 'formNewUser',
    component: FormNewUserView
  },
  {
    path: '/verify/:verify',
    name: 'verifyView',
    component: VerifyView
  },
  {
    path: '/terms',
    name: 'terms',
    component: TermsView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/suscripcion',
    name: 'suscripcion',
    component: SuscripcionView
  },
  {
    path: '/menu_suscripcion',
    name: 'suscripcionMenu',
    component: SuscripcionMenuView
  },
  {
    path: '/formpay',
    name: 'formpay',
    component: FormPayView
  },
  {
    path: '/success/:sessionid',
    name: 'success',
    component: SuccessView
  },
  {
    path: '/orders/:idBot?',
    name: 'orders',
    component: OrdersView
  },
  {
    path: '/client',
    name: 'client',
    component: ClientView
  },
  {
    path: '/client_save/:idClient?',
    name: 'client_save',
    component: ClientSaveView
  },
  {
    path: '/wallets',
    name: 'wallets',
    component: WalletsView
  },
  {
    path: '/bot',
    name: 'bot',
    component: BotView
  },
  {
    path: '/bot_save/:idBot?',
    name: 'bot_save',
    component: BotSaveView
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: TicketView
  },
  {
    path: '/ticket_save',
    name: 'ticket_save',
    component: TicketSaveView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/public/AboutView.vue')
  },

  // ADMIN
  {
    path: '/admin_home',
    name: 'adminHome',
    component: AdminHomeView
  },
  {
    path: '/admin_users',
    name: 'adminUsers',
    component: AdminUsersView
  },
  {
    path: '/admin_user/:idUser?',
    name: 'adminUser',
    component: AdminUserView
  },
  {
    path: '/admin_user_new',
    name: 'adminUserNew',
    component: AdminUserNewView
  },
  {
    path: '/admin_bots',
    name: 'adminBots',
    component: AdminBotsView
  },
  {
    path: '/admin_trades/:idBot?',
    name: 'adminTrades',
    component: AdminTradesView
  },
  {
    path: '/admin_subs',
    name: 'adminSubs',
    component: AdminSubsView
  },
  {
    path: '/admin_sub/:idSub?',
    name: 'adminSub',
    component: AdminSubView
  },
  {
    path: '/admin_comerciales',
    name: 'adminComerciales',
    component: AdminComercialesView
  },
  {
    path: '/admin_comercial_save/:idComercial?',
    name: 'adminComercialSave',
    component: AdminComercialSaveView
  },
  {
    path: '/admin_pagos',
    name: 'adminPagos',
    component: AdminPagosView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // mode: 'abstract'
})

export default router
