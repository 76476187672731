<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <div class="d-flex flex-column mb-6 bg-surface-variant">
                <v-row no-gutters style="flex-wrap: nowrap;">
                    <v-col>
                        <v-card elevation="2" outlined shaped>
                            <v-row justify="center">
                                <v-card-title class="text-h5">{{ title }}</v-card-title>
                            </v-row>
                            <v-divider class="mx-4"></v-divider>
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <br>
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="2" xs="0" sm="0" md="3" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="8" xs="8" sm="8" md="6" class="flex-grow-0 flex-shrink-0">
                                            <validation-provider v-slot="{ errors }" name="Nombre"
                                                rules="required|max:10">
                                                <v-text-field v-model="name" :counter="10" :error-messages="errors"
                                                    label="Nombre" required></v-text-field>
                                            </validation-provider>
                                            <!-- <v-text-field v-model="name" :counter="20" :rules="nameRules" label="Nombre"
                                                :value="content.name" required></v-text-field> -->
                                            <validation-provider v-slot="{ errors }" name="Apellidos"
                                                rules="required|max:20">
                                                <v-text-field v-model="lastName" :counter="20" :error-messages="errors"
                                                    label="Apellidos" required></v-text-field>
                                            </validation-provider>
                                            <!-- <v-text-field v-model="lastName" :counter="20" :rules="nameRules"
                                                label="Apellidos" :value="content.lastName" required></v-text-field> -->
                                            <validation-provider v-slot="{ errors }" name="email"
                                                rules="required|email">
                                                <v-text-field v-model="email" :error-messages="errors" label="E-mail"
                                                    required readonly></v-text-field>
                                            </validation-provider>
                                            <!-- <v-text-field v-model="email" :rules="emailRules" label="E-mail"
                                                :value="content.email" required readonly></v-text-field> -->
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                            </v-card-actions>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="4" xs="3" sm="3" md="4">
                                        </v-col>
                                        <v-col cols="4" xs="3" sm="3" md="4">
                                            <v-row no-gutters justify="center">
                                                <v-btn :disabled="invalid" color="success" @click="saveUser">
                                                    Guardar
                                                </v-btn>
                                            </v-row>
                                            <br>
                                            <!-- <v-row no-gutters justify="center">
                                                <v-btn color="blue-grey" @click="goSuscripcion">
                                                    Suscripcion
                                                </v-btn>
                                            </v-row>
                                            <br> -->
                                            
                                            <v-row no-gutters justify="center">
                                                <v-btn :disabled="invalid" color="success" @click="dialog = !dialog">
                                                    Cambiar Password
                                                </v-btn>
                                            </v-row>
                                            <br>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </validation-observer>
                        </v-card>
                    </v-col>
                </v-row>
                <v-fade-transition hide-on-leave>
                    <v-card v-if="dialog" append-icon="$close" class="mx-auto" elevation="16" max-width="500"
                        title="Send a receipt">
                        <template v-slot:append>
                            <v-btn icon="$close" variant="text" @click="dialog = false"></v-btn>
                        </template>
                        <v-divider></v-divider>
                        <div class="py-12 text-center">
                            <v-icon class="mb-6" color="success" icon="mdi-check-circle-outline" size="128"></v-icon>
                            <div class="text-h4 font-weight-bold">Cambie su contraseña</div>
                            <v-row>
                                <v-col cols="2"></v-col>
                                <v-col cols="8"><v-text-field :type="show4 ? 'text' : 'password'" v-model="old_password"
                                        :error-messages="errors" label="Password" required></v-text-field>
                                    <validation-provider vid="confirm" v-slot="{ errors }" name="Nueva Contraseña"
                                        rules="required|pass">
                                        <v-text-field :type="show4 ? 'text' : 'password'" v-model="new_password"
                                            :error-messages="errors" label="Nueva Contraseña" required></v-text-field>
                                    </validation-provider>
                                    <validation-provider  v-slot="{ errors }" name="Confirmacion Password"
                                        rules="required|confirmed:@confirm">
                                        <v-text-field type="password" v-model="password1" :error-messages="errors"
                                            label="Confirme Nueva Contraseña" required></v-text-field>
                                    </validation-provider>
                                </v-col>
                            </v-row>
                        </div>
                        <v-divider></v-divider>
                        <div class="pa-4 text-end">
                            <v-row>
                                <v-col cols="1"></v-col>
                                <v-col cols="4">
                                    <v-btn class="text-none" color="success" min-width="92" variant="outlined" rounded
                                        @click="changePass">
                                        Guardar
                                    </v-btn>
                                </v-col>
                                <v-col cols="1"></v-col>
                                <v-col cols="4">

                                    <v-btn class="text-none" color="medium-emphasis" min-width="92" variant="outlined"
                                        rounded @click="dialog = false">
                                        Cerrar
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card>
                </v-fade-transition>
                <br>
                <!-- <v-divider class="mx-4" horizontal></v-divider> -->
                <br>
                <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                    <v-col  cols="1" xs="1" sm="1" md="4"></v-col>
                    <v-col cols="10" xs="8" sm="8" md="4">
                        <v-alert prominent :type="types">
                            <v-row>
                                <v-col class="grow">
                                    {{ msgError }}
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn @click="closeAlert">OK</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import User from '../../models/user';
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} no puede estar vacio.',
})

extend('max', {
    ...max,
    message: '{_field_} no puede ser mayor de {length} caracteres,',
})

extend('email', {
    ...email,
    message: 'Email debe ser valido.',
})

extend("pass", {
    params: ["otherValue"],
    validate: (value) => {
        if (
            value === null ||
            value.length < 6 ||
            !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#!@#]).+$/.test(value)
        ) {
            return false;
        }
        return true;
    },
    message:
        "La contraseña no cumple los requisitos."
});

export default {
    name: 'ProfileComponent',
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            title: 'PERFIL',
            user: new User('', '', ''),
            content: '',
            name: '',
            lastName: '',
            email: '',
            old_password: '',
            new_password: '',
            password1: '',
            submitted: false,
            successful: false,
            message: '',
            valid: '',
            isShow: false,
            show4: '',
            errors: '',
            msgError: '',
            types: "success",
            dialog: false
        };
    },
    computed: {
        currentUser() {
            return this.user;
        }
    },
    mounted() {
        UserService.getUserProfile(this.$store.state.auth.user.email).then(
            response => {
                this.user.email = response.data.email;
                this.user.username = response.data.username;
                this.content = response.data
                this.name = this.content.name
                this.lastName = this.content.lastName
                this.email = this.content.email
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                EventBus.dispatch("logout");
            }
        );
    },
    methods: {
        async saveUser() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return;
                }

                // Wait until the models are updated in the UI
                this.$nextTick(() => {
                    try {
                        this.message = '';
                        this.submitted = true;
                        this.user.name = this.name
                        this.user.lastName = this.lastName
                        this.user.email = this.email

                        UserService.saveUser(this.user).then(
                            response => {
                                // console.log(response.email)
                                this.user.email = response.email;
                                this.user.username = response.username;
                                this.content = response
                                this.name = this.content.name
                                this.lastName = this.content.lastName
                                // this.email = this.content.email
                                this.msgError = 'Datos guardados correctamente.'
                                this.types = 'success'
                                this.isShow = true
                            },
                            error => {
                                this.content =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                this.msgError = error
                                console.log(error.response.data)
                                this.types = 'error'
                                this.isShow = true
                            }
                        );
                    } catch (error) {
                        this.error = true
                        this.msgError = error.response
                        // console.log(error.response.data)
                        this.types = 'error'
                        this.isShow = true
                    }
                });
            });
        },
        goSuscripcion() {
            this.$router.push("/suscripcion");
        },
        closeAlert() {
            this.isShow = false
        },
        changePass() {
            UserService.changePass(this.$store.state.auth.user.email, this.old_password, this.new_password).then(
            response => {
                console.log(response);
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                    console.log(error.toString());
            }
        );
        }
    }
}
</script>