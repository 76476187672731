<template>
    <div class="sr-root">
        <div class="sr-main">
            <header class="sr-header">
                <div class="sr-header__logo" />
            </header>

            <div class="sr-payment-summary completed-view">
                <h1>Your payment is {{ this.status }}</h1>
                <h4>View CheckoutSession response:</h4>
            </div>
            <div class="sr-section completed-view">
                <div v-if="session" class="sr-callout">
                    <!-- <pre>{{ JSON.stringify(session, null, 2) }} </pre> -->
                </div>
                <button onclick="window.location.href = '/';">
                    Restart demo
                </button>
            </div>
        </div>

        <div class="sr-content">
            <div class="pasha-image-stack">
                <img src="https://picsum.photos/280/320?random=1" width="140" height="160">
                <img src="https://picsum.photos/280/320?random=2" width="140" height="160">
                <img src="https://picsum.photos/280/320?random=3" width="140" height="160">
                <img src="https://picsum.photos/280/320?random=4" width="140" height="160">
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';

export default {
    name: 'SuccessComponent',
    data() {
        return {
            session: '',
            status: '',
            amount_total: '',
            payment_intent: '',
            payment_status: ''
            // user: new User('', ''),
            // loading: false,
            // message: '',
            // error: '',
            // email: '',
            // emailRules: [
            //     v => !!v || 'E-mail is required',
            //     v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            // ],
            // show4: false,
            // password: '',
            // passRules: {
            //     required: value => !!value || 'Required.',
            //     min: v => v.length >= 6 || 'Min 6 characters',
            //     emailMatch: () => (`The email and password you entered don't match`),
            // },
            // valid: '',
            // isShow: false,
            // msgError: '',
            // types: "success",
            // success: false
        };
    },
    mounted() {
        UserService.getSession(this.$route.params.sessionid).then(
            response => {
                console.log(response.data)
                this.session = response.data
                this.status = response.data.status
                this.amount_total = response.data.amount_total
                this.payment_intent = response.data.payment_intent
                this.payment_status = response.data.payment_status
                // this.chargeCheckout(response)
                if (this.status === 'complete' && this.payment_status === 'paid') {
                    this.insertPay()
                }
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                console.log(error)
                // this.messages.value.push(error);
            }
        );
        // console.log(this.$route.params.sessionid)
    },
    computed: {

    },
    created() {
        if (!this.loggedIn) {
            EventBus.dispatch("logout");
        }
    },
    methods: {
        insertPay() {
            UserService.postPay(this.$store.state.auth.user.email, this.payment_intent).then(
                response => {
                    console.log(response.data)
                    
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log(error)
                    // this.messages.value.push(error);
                }
            );
        }
    }
}
</script>