<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-sheet v-for="n in 1" :key="n" class="ma-2 pa-2" outlined shaped>
                <v-row no-gutters style="flex-wrap: nowrap;">
                    <v-col cols="2" class="flex-grow-0 flex-shrink-0">
                    </v-col>
                    <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                        <v-card elevation="2" outlined shaped>
                            <v-card-title class="text-h5">Perfil</v-card-title>
                            <!-- <StripeComponent/> -->
                            <CheckoutComponent/>
                            <!-- <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-row no-gutters style="flex-wrap: nowrap;">
                                    <v-col cols="2" class="flex-grow-0 flex-shrink-0">
                                    </v-col>
                                    <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                                        <validation-provider v-slot="{ errors }" name="Nombre" rules="required|max:10">
                                            <v-text-field v-model="price" :counter="10" :error-messages="errors"
                                                label="Precio" required readonly></v-text-field>
                                        </validation-provider>
                                        <validation-provider v-slot="{ errors }" name="Moneda" rules="required|max:10">
                                            <v-text-field v-model="moneda" :counter="10" :error-messages="errors"
                                                label="Moneda" required readonly></v-text-field>
                                        </validation-provider>
                                        <div>
                                            <stripe-checkout ref="checkoutRef" mode="payment" :pk="publishableKey"
                                                :line-items="lineItems" :success-url="successURL" :cancel-url="cancelURL"
                                                 />
                                            <button @click="submit">Pay now!</button>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters style="flex-wrap: nowrap;">
                                    <v-col cols="3" class="flex-grow-0 flex-shrink-0">
                                    </v-col>
                                    <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                                        <v-btn :disabled="invalid" color="blue-grey" class="mr-8">
                                            Suscripcion
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </validation-observer> -->

                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                    <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
                    <v-col cols="6" class="flex-grow-0 flex-shrink-0">
                        <v-alert prominent :type="types">
                            <v-row>
                                <v-col class="grow">
                                    {{ msgError }}
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn @click="closeAlert">OK</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-sheet>
        </div>
    </v-container>
</template>

<script>
import User from '../../models/user';
// import StripeComponent from '../../components/StripeComponent.vue'
import CheckoutComponent from '../CheckoutComponent.vue';

export default {
    name: 'ProfileComponent',
    components: {
        // StripeComponent,
        CheckoutComponent
        // ValidationProvider,
        // ValidationObserver,
    },
    data() {
        return {
            user: new User('', '', ''),
            price: '',
            moneda: '',
            isShow: false,
            msgError: '',
            types: "success"
        };
    },
    computed: {
        currentUser() {
            return this.user;
        }
    },
    mounted() {
        
    },
    methods: {
        closeAlert() {
            this.isShow = false
        }
    }
}
</script>