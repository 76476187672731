<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                            <!-- <v-divider class="mx-4"></v-divider> -->
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                            </v-col>
                            <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-card class="pa-2">
                                    <v-row no-gutters style="flex-wrap: nowrap;">
                                        <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                                        </v-col>
                                        <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                            <v-data-table :headers="headers" :items="items"
                                                :search="search"></v-data-table>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <v-row no-gutters>
                            <v-col cols="3" xs="0" sm="0" md="5"> </v-col>
                            <v-col cols="6" xs="2" sm="2" md="2">
                                <v-row no-gutters justify="center">
                                    <v-btn color="blue-grey" @click="paySubscription">
                                        ELEGIR SUSCRIPCION
                                    </v-btn>
                                </v-row>
                            </v-col>
                        </v-row>
                        <br>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                <v-col cols="10" xs="8" sm="8" md="4">
                    <v-alert prominent :type="types">
                        <v-row>
                            <v-col class="grow">
                                {{ msgError }}
                            </v-col>
                            <v-col class="shrink">
                                <v-btn @click="closeAlert">OK</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
// import User from '../models/user';

export default {
    name: 'SuscripcionComponent',
    components: {
    },
    data() {
        return {
            idBot: '',
            title: "SUSCRIPCIONES",
            // Table
            headers: [
                { text: "Inicio", value: "start_time", align: "center" },
                { text: "Fin", value: "end_time", align: "center" },
                { text: "Precio", value: "quantity", align: "center" },
                { text: "Pagado", value: "payed", align: "center" },
            ],
            items: [],
            right: null,
            search: '',
            start: false,
            colors: "success",
            msgBtn: '',
            isShow: false,
            msgError: '',
            types: "success",
        };
    },
    computed: {},
    mounted() {
        UserService.getPays(this.$store.state.auth.user.email).then(
            response => {
                // console.log(response)
                this.items = response.data;
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                // EventBus.dispatch("logout");
            }
        );
    },
    methods: {
        paySubscription() {
            UserService.chekPeriodSus(this.$store.state.auth.user.email).then(
                response => {
                    if (response) {
                        // console.log(response.data)
                        if (response.data > 15) {
                            this.msgError = 'Todavia le faltan ' + response.data + ' días para que finalice la suscripcón. ' 
                            + 'Si quiere renovar la suscripción, abra un ticket'
                            // console.log(response)
                            this.types = 'success'
                            this.isShow = true
                        } else {
                            this.$router.push("/menu_suscripcion");
                        }

                    }

                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    EventBus.dispatch("logout");
                }
            );


        },
        paySubs() {
            this.$router.push("/formpay");
            // this.$router.push("/formpay");
        },
        // paySubs() {
        //     UserService.getCheckout(this.$store.state.auth.user).then(
        //         response => {
        //             console.log(response)
        //             // window.location.href = response.data
        //         },
        //         error => {
        //             this.content =
        //                 (error.response && error.response.data && error.response.data.message) ||
        //                 error.message ||
        //                 error.toString();
        //             EventBus.dispatch("logout");
        //         }
        //     );
        // },
        closeAlert() {
            this.isShow = false
        }
    }
}
</script>