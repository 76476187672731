<template>
    <FormPayComponent />
  </template>
    
  <script>
  import FormPayComponent from '../../components/user/FormPayComponent.vue'
  
  export default {
    name: 'IndexView',
    components: {
        FormPayComponent,
    },
  }
  </script>