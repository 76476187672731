<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
            <v-col cols="3">
                <v-btn size="large" @click="toUsers()">Users</v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn size="large" @click="toComerciales()">Comerciales</v-btn>
            </v-col>
        </v-row>
        <v-row class="text-center">
            <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
            <v-col cols="3">
                <v-btn size="large" @click="toSubs()">Suscripciones</v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn size="large" @click="toPagos()">Pagos</v-btn>
            </v-col>
        </v-row>
        <v-row class="text-center">
            <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
            <v-col cols="3">
                <v-btn size="large" @click="toBots()">Bots</v-btn>
            </v-col>
            <v-col cols="3">
                <v-btn size="large" @click="logOut()">Salir</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>


export default {
    name: 'AdminMenu',
    methods: {
        toUsers() {
            const path = '/admin_users'
            if (this.$route.path !== path) this.$router.push(path)
        },
        toComerciales() {
            const path = '/admin_comerciales'
            if (this.$route.path !== path) this.$router.push(path)
        },
        toBots() {
            const path = '/admin_bots'
            if (this.$route.path !== path) this.$router.push(path)
        },
        toSubs() {
            const path = '/admin_subs'
            if (this.$route.path !== path) this.$router.push(path)
        },
        toPagos() {
            const path = '/admin_pagos'
            if (this.$route.path !== path) this.$router.push(path)
        },
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/");
        },
    },
}
</script>