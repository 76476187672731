<template>
    <div id="truste-consent-track" style="position: relative; z-index: 999999; opacity: 1; display: block;">
        <div id="truste-consent-content" style="overflow: hidden;">
            <div id="truste-consent-text" class="truste-messageColumn">Este sitio web utiliza cookies y tecnologías
                similares para el funcionamiento del sitio web, analizar nuestro tráfico y mantener la publicidad de
                terceros, tal y como se describe en nuestra&nbsp;
                <a href="https://www.xerox.com/en-us/about/privacy-policy" target="_blank" id="truste-privacy-button"
                    style="cursor:pointer;" class="truste-cookie-link">Política de privacidad</a>. Puede dar su
                consentimiento para que utilicemos todas estas tecnologías,&nbsp;<a
                    onclick="gestionarCookies">gestionar su configuración</a>&nbsp;o
                rechazar todas las tecnologías no esenciales. Si se rechazan todas las tecnologías no esenciales, puede
                verse afectado el rendimiento general de la web.</div>
            <div id="truste-consent-buttons" class="truste-buttonsColumn">
                <span id="truste-repop-msg"
                    style="padding: 7px 10px; background: #F9EDBE; border:1px solid #F0C36D; margin: 11px 0px 13px; line-height: 16px;color: #AF7501; display:none;"></span>
                <button id="truste-consent-button" @click="acceptCookies">Permitir todo</button>
                <button id="truste-consent-required" @click="declineCookies">Rechazar todo</button>
                <button id="truste-show-consent" aria-haspopup="true" @click="gestionarCookies">Gestionar su configuración</button>
                <div id="truste-consent-close">
                    <img src="https://consent.trustarc.com/get?name=xerox_close_icon.png" width="15" alt="close icon">
                </div>
            </div>
        </div>
    </div>
</template>

<!-- <template>
    <div>
        <v-snackbar  v-model="snackbar" vertical>
            <div class="cookie-consent">This is a snackbar message</div>

            <span class="cookie-consent-message">
                We use tracking cookies to understand how you use the product
                and help us improve it.
                Please accept cookies to help us improve.
                <a role="button" href="https://www.cookiesandyou.com/" target="_blank"> Learn more. </a>
            </span>

            <template v-slot:actions>
                <section>
                    <button type="button" class="cookie-consent-compliance" @click="acceptCookies"> Got It! </button>
                </section>
            </template>
        </v-snackbar>
    </div>
</template> -->

<script>
export default {
    name: "CookieBanner",
    data: () => ({
        snackbar: true,
    }),
    methods: {
        acceptCookies() {
            // this.$posthog.opt_in_capturing();
        },
        declineCookies() {
            // this.$posthog.opt_out_capturing();
        },
        gestionarCookies() {
            // this.$posthog.opt_out_capturing();
        }
    }

};
</script>
<style lang="scss" scoped>
// .cookie-consent {
//     position: relative;
//     z-index: 9999;
//     width: 100%;
//     padding: 1rem 0;
//     display: -ms-flexbox;
//     display: flex;
//     -ms-flex-wrap: wrap;
//     flex-wrap: wrap;
//     -ms-flex-align: center;
//     align-items: center;
//     -ms-flex-pack: center;
//     justify-content: center;
//     background: #ced4da;
// }

// .cookie-consent-compliance,
// .cookie-consent-message {
//     margin: .5rem 1rem;
// }

// .cookie-consent-compliance {
//     cursor: pointer;
//     color: #fff;
//     padding: .5rem 2rem;
//     background: #237afc;
//     border: 2px solid currentColor;
// }

#truste-consent-track {
    border: 0;
    padding: 15px;
    background: rgb(51 51 51 / .82);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    direction: ltr;
}

#truste-consent-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1015px;
    margin: 0 auto;
    padding-top: 15px;
}

#truste-consent-text {
    color: #fff;
    font-size: 12px;
    margin: 0 50px 0 0;
    line-height: 18px;
}

#truste-consent-text a {
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
}

.truste-messageColumn {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #fff;
    margin: 15px 0px 15px 0px;
    float: left;
}

#truste-privacy-button {
    color: #ffffff;
    text-decoration: underline;
}

.truste-cookie-link {
    font-family: Roboto, sans-serif;
    color: #fff;
}

#truste-consent-button,
#truste-consent-required {
    /* background-color: #D92231; */
    /* color: white; */
    background: #fff;
    color: #000;
    padding: 14px 30px;
    border: 1px solid #000;
    border-radius: 0;
    cursor: pointer;
    font-family: RobotoCondensed, sans-serif;
    font-size: 14px;
    width: 100%;
    white-space: nowrap;
    text-transform: uppercase;
}

.truste-buttonsColumn {
    margin: 0;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    min-width: 195px;
}

#truste-show-consent {
    background-color: transparent;
    color: #fff;
    border: 0;
    padding: 4px 0;
    border-radius: 0;
    cursor: pointer;
    font-family: RobotoCondensed, sans-serif;
    font-size: 16px;
    width: 100%;
    white-space: nowrap;
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 3px;
}

div#truste-consent-close {
    cursor: pointer;
    position: absolute;
    top: 15px;
    right: 15px;
}
</style>
