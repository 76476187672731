<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <validation-observer ref="observer" v-slot="{ invalid }">
                                    <v-form ref="form" v-model="valid" lazy-validation>
                                        <v-row no-gutters>
                                            <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                                            </v-col>
                                            <v-col cols="8" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                                <validation-provider v-slot="{ errors }" name="Exchange"
                                                    rules="required">
                                                    <v-select v-model="exchange" :items="itemsExchange"
                                                        item-text="exchange" item-value="id" label="Select Exchange"
                                                        persistent-hint return-object single-line
                                                        @change="onChange($event)"></v-select>
                                                    <div class="invalid-feedback">
                                                        <p style="color:red">{{ errors[0] }}</p>
                                                    </div>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Api Key"
                                                    rules="required|max:64">
                                                    <v-text-field v-model="apikey" :counter="64"
                                                        :error-messages="errors" label="Api Key"
                                                        required></v-text-field>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Secret"
                                                    rules="required|max:64">
                                                    <v-text-field v-model="secret" :counter="64"
                                                        :error-messages="errors" label="Secret" required></v-text-field>
                                                </validation-provider>
                                                <div v-if="isPassphrase">
                                                    <validation-provider v-slot="{ errors }" name="Passphrase"
                                                        rules="required|max:64">
                                                        <v-text-field v-model="passphrase" :counter="64"
                                                            :error-messages="errors" label="Passphrase"
                                                            required></v-text-field>
                                                    </validation-provider>
                                                </div>
                                                <div v-if="!isPassphrase">
                                                </div>

                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters>
                                            <v-col cols="2" xs="0" sm="0" md="1"></v-col>
                                            <v-col cols="4" xs="2" sm="2" md="4">
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn :disabled="invalid" color="success"
                                                        @click="saveClient">Guardar</v-btn>
                                                </v-card-actions>
                                            </v-col>
                                            <v-col cols="4" xs="2" sm="2" md="4">
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="information" @click="connect">Conectar</v-btn>
                                                </v-card-actions>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </validation-observer>
                            </v-col>
                        </v-row>

                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                            <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="4">
                                <v-alert prominent :type="types">
                                    <v-row>
                                        <v-col class="grow">
                                            {{ msgError }}
                                        </v-col>
                                        <v-col class="shrink">
                                            <v-btn @click="closeAlert">OK</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-alert>
                            </v-col>
                        </v-row>

                        <v-row v-show="isSelected" no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-row no-gutters justify="center">
                                    <v-card-title class="text-h5">{{ titleTutorial }}</v-card-title>
                                </v-row>
                                <v-row no-gutters justify="center">
                                    <p>{{ textTutorial }}</p>
                                    <img :src="pic_01" v-bind:alt="pic_01">
                                </v-row>
                                <br>
                                <v-row no-gutters justify="center">
                                    <v-btn @click="goToAPI">{{ exchange.exchange }}</v-btn>
                                    <!-- <div v-for="r in urlTutorial" :key="r">
                                        <a :href="'/job/' + r.id"> {{ r.job_title }} </a>
                                    </div> -->
                                    <!-- <a href="`${urlTutorial}`" target="_blank">{{exchange.exchange}}</a>
                                    <a v-bind:href="''"> {{ urlTutorial }} </a> -->
                                    <img :src="pic_02" v-bind:alt="pic_02">
                                </v-row>
                                <br>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <!-- <v-row justify="center">
                <v-col cols="8" xs="8" sm="8" md="8"></v-col>
                <v-col cols="4" xs="4" sm="4" md="4">
                    <div class="home">
                        <div v-show="!modalActive">
                            <button @click="toggleModal" type="button">Asistente</button>
                        </div>
                        <Asistente @close="toggleModal" :modalActive="modalActive">
                            <div class="modal-content">
                                <h1>This is a Modal Header</h1>
                                <p>This is a modal message</p>
                            </div>
                        </Asistente>
                    </div>
                </v-col>
            </v-row> -->
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import Client from "../../models/client";
// import Asistente from '../../components/user/AssistantComponent.vue'
import { ref } from "vue";
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} no puede estar vacio.',
})

extend('max', {
    ...max,
    message: '{_field_} no puede ser mayor de {length} caracteres,',
})

extend('email', {
    ...email,
    message: 'Email debe ser valido.',
})

export default {
    name: 'ClientSaveComponent',
    components: {
        ValidationProvider,
        ValidationObserver,
        // Asistente
    },
    data() {
        return {
            title: 'API',
            client: new Client("", "", ""),
            idClient: '',
            // Dialog Config
            apikey: "",
            secret: "",
            passphrase: "",
            itemsExchange: [],
            exchange: '',
            isPass: false,
            valid: '',
            isShow: false,
            mostrar: true,
            msgError: '',
            types: "success",
            drawer: true,
            label: 'Cerrar Dialogo',
            isHidden: false,
            isSelected: false,
            titleTutorial: '',
            textTutorial: '',
            urlTutorial: '',
            pic_01: '',
            pic_02: '',
            isSuccess: false
        };
    },
    computed: {
        isPassphrase() {
            if (this.isPass) {
                return true
            } else {
                return false
            }
        },
    },
    setup() {
        const modalActive = ref(true);
        const toggleModal = () => {
            modalActive.value = !modalActive.value;
        };
        return { modalActive, toggleModal };
    },

    created() {
    },
    mounted() {
        if (this.$route.params.idClient !== null) {
            this.idClient = this.$route.params.idClient
        }
        UserService.getExchanges().then(
            response => {
                // console.log(response.data)
                this.itemsExchange = response.data
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
        if (this.idClient !== '' && this.idClient !== undefined) {

            UserService.getClient(this.$store.state.auth.user.email, this.idClient).then(
                response => {
                    // console.log(response.data)
                    this.content = response.data
                    this.client.id = this.idClient
                    this.apikey = this.content.apiKey
                    this.secret = this.content.secret
                    this.passphrase = this.content.passphrase
                    this.exchange = this.content.exchange
                    this.isPass = this.content.exchange.passphrase
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        }
    },
    methods: {
        // config() {
        //     this.$store.dispatch("auth/logout");
        //     this.$router.push("/login");
        // },
        saveClient() {
            this.message = "";
            this.submitted = true;
            this.client.apiKey = this.apikey;
            this.client.secret = this.secret;
            this.client.passphrase = this.passphrase;
            this.client.user = this.$store.state.auth.user;
            this.client.exchange = this.exchange;
            UserService.postClient(this.client).then(
                response => {
                    console.log(response)
                    this.msgError = response
                    this.types = 'success'
                    this.isShow = true
                    this.isSuccess = true
                    // this.$router.go(0);
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.msgError = error.response.data
                    console.log(error.response.data)
                    this.types = 'error'
                    this.isShow = true
                    this.isSuccess = false
                }
            );
        },
        onChange(exchange) {
            // console.log(exchange.passphrase)
            this.isSelected = true
            this.isPass = exchange.passphrase
            this.titleTutorial = 'Tutorial ' + exchange.exchange
            this.textTutorial = 'Accede a la siguiente web para crear la API.'
            if (exchange.exchange === 'KUCOIN') {
                this.urlTutorial = 'https://www.kucoin.com/es/support/360015102174#:~:text=Ve%20a%20KuCoin.com%20e,y%20las%20restricciones%20de%20IP.'
            } else {
                this.urlTutorial = 'https://www.binance.com/es/support/faq/c%C3%B3mo-crear-claves-api-en-binance-360002502072'
            }

            // this.pic_01 = 'pantalla_01_kucoin.png'
            // this.pic_02 = 'pantalla_02_kucoin.png'
            // UserService.getListByRiesgo(riesgo.riesgo).then(
            //     response => {
            //         this.itemsSymbols = response.data;
            //     },
            //     error => {
            //         this.content =
            //             (error.response &&
            //                 error.response.data &&
            //                 error.response.data.message) ||
            //             error.message ||
            //             error.toString();
            //         this.msgError = error.response.data
            //         // console.log(error.response.data)
            //         this.types = 'error'
            //         this.isShow = true
            //     }
            // );
        },
        goToAPI() {
            // this.$router.push(this.urlTutorial);
            window.open(this.urlTutorial, '_blank');
        },
        connect() {
            this.message = "";
            this.submitted = true;
            this.client.apiKey = this.apikey;
            this.client.secret = this.secret;
            this.client.passphrase = this.passphrase;
            this.client.user = this.$store.state.auth.user;
            UserService.connect(this.client.user.email, this.idClient).then(
                response => {
                    this.msgError = response
                    this.types = 'success'
                    this.isShow = true
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.msgError = error.response.data
                    // console.log(error.response.data)
                    this.types = 'error'
                    this.isShow = true
                }
            );
        },

        closeAlert() {
            if (this.isSuccess) {
                this.isShow = false
                this.$router.push('/client')
            } else {
                this.isShow = false
            }

        }
    }
}
</script>
<style lang="scss" scoped>
.home {
    background-color: rgba(0, 0, 28, 0.5);
    height: 50vh;
    display: flex;
    // position: fixed;
    justify-content: center;
    align-items: center;

    .modal-content {
        display: flex;
        flex-direction: column;

        h1,
        p {
            margin-bottom: 16px;
        }

        h1 {
            font-size: 32px;
        }

        p {
            font-size: 18px;
        }
    }
}
</style>