<template>
    <v-container>
        <v-row class="text-center">
            <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
            <v-col cols="6" class="flex-grow-0 flex-shrink-0">
                <v-app-bar-title>ADMIN</v-app-bar-title>
            </v-col>
        </v-row>
        <AdminMenu/>
    </v-container>
</template>
  
<script>
import AdminMenu from "../../components/admin/AdminMenuComponent.vue";

export default {
    name: 'AdminHome',
    components: {
        AdminMenu
    },
    // methods: {
    //     toUsers() {
    //         this.$router.push("/admin_users");
    //     },
    //     toBots() {
    //         this.$router.push("/admin_bots");
    //     },
    //     logOut() {
    //         this.$store.dispatch("auth/logout");
    //         this.$router.push("/");
    //     },
        
    // },
}
</script>
  