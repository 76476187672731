import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login(user) {
    process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;
    return api
      .post('signin', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  getToken(user){
    return api
      .get('getToken', user.email)
      .then(response => {
        if (response.data.user) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  getProfile(user){
    return api
      .get('getProfile', user.email)
      .then(response => {
        if (response.data.user) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  register(user) {
    return api
      .post('signup', user)
      .then(response => {
        console.log(response.data.message)
        return response.data.message;
      });
  }
}

export default new AuthService();
