<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                <div v-if="isNew">
                                    <validation-observer ref="observer" v-slot="{ invalid }">
                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-row no-gutters>
                                                <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                                                </v-col>
                                                <v-col cols="8" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                                    <validation-provider v-slot="{ errors }" name="Titulo"
                                                        rules="required|max:24">
                                                        <v-text-field v-model="titulo" :counter="24"
                                                            :error-messages="errors" label="Titulo"
                                                            required></v-text-field>
                                                    </validation-provider>
                                                    <validation-provider v-slot="{ errors }" name="Descripcion"
                                                        rules="required|max:40">
                                                        <v-textarea v-model="descripcion" :error-messages="errors"
                                                            required outlined name="input-7-4"
                                                            label="Descripción"></v-textarea>
                                                    </validation-provider>
                                                    <v-textarea outlined disabled v-model="respuesta"
                                                        :error-messages="errors" label="Respuesta"
                                                        readonly></v-textarea>
                                                    <v-text-field disabled v-model="estado" :error-messages="errors"
                                                        label="Estado" readonly></v-text-field>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                    </v-card-actions>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="4" xs="3" sm="3" md="4">
                                                </v-col>
                                                <v-col cols="4" xs="3" sm="3" md="4">
                                                    <v-row no-gutters justify="center">
                                                        <v-btn :disabled="invalid" color="success"
                                                            @click="saveTicket">Guardar</v-btn> </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-form>
                                    </validation-observer>
                                </div>
                                <div v-if="!isNew">
                                    <v-form ref="form" v-model="valid" lazy-validation>
                                        <v-row no-gutters>
                                            <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0">
                                            </v-col>
                                            <v-col cols="8" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                                <validation-provider v-slot="{ errors }" name="Titulo"
                                                    rules="required|max:24">
                                                    <v-text-field readonly v-model="titulo" :counter="24"
                                                        :error-messages="errors" label="Titulo" required></v-text-field>
                                                </validation-provider>
                                                <validation-provider v-slot="{ errors }" name="Descripcion"
                                                    rules="required|max:40">
                                                    <v-textarea readonly v-model="descripcion" :error-messages="errors"
                                                        required outlined name="input-7-4"
                                                        label="Descripción"></v-textarea>
                                                </validation-provider>
                                                <v-textarea outlined disabled v-model="respuesta"
                                                    :error-messages="errors" label="Respuesta" readonly></v-textarea>
                                                <v-text-field disabled v-model="estado" :error-messages="errors"
                                                    label="Estado" readonly></v-text-field>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                </v-card-actions>
                                            </v-col>
                                        </v-row>
                                    </v-form>
                                </div>
                            </v-col>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                            <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="4">
                                <v-alert prominent :type="types">
                                    <v-row>
                                        <v-col class="grow">
                                            {{ msgError }}
                                        </v-col>
                                        <v-col class="shrink">
                                            <v-btn @click="closeAlert">OK</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import Ticket from "../../models/ticket";
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} no puede estar vacio.',
})

extend('max', {
    ...max,
    message: '{_field_} no puede ser mayor de {length} caracteres,',
})

extend('email', {
    ...email,
    message: 'Email debe ser valido.',
})

export default {
    name: 'TicketSaveComponent',
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            title: 'Ticket',
            ticket: new Ticket("", "", ""),
            idTicket: '',
            // Dialog Config
            titulo: "",
            descripcion: "",
            respuesta: "",
            estado: '',
            itemsExchange: [],
            exchange: '',
            valid: '',
            isShow: false,
            errors: '',
            msgError: '',
            types: "success",
            success: false,
            isNew: false
        };
    },
    computed: {},

    created() {
    },
    mounted() {
        this.idTicket = localStorage.ticket
        localStorage.removeItem("ticket");
        if (this.idTicket !== '' && this.idTicket !== undefined) {

            UserService.getTicket(this.idTicket).then(
                response => {
                    this.content = response.data
                    this.titulo = this.content.titulo
                    this.descripcion = this.content.descripcion
                    this.respuesta = this.content.respuesta
                    this.estado = this.content.estado
                    this.isNew = false
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                    if (error.response && error.response.status === 403) {
                        EventBus.dispatch("logout");
                    }
                }
            );
        } else {
            this.isNew = true
        }
    },
    methods: {
        saveTicket() {
            this.message = "";
            this.submitted = true;
            this.ticket.titulo = this.titulo;
            this.ticket.descripcion = this.descripcion;
            this.ticket.respuesta = this.respuesta;
            this.ticket.user = this.$store.state.auth.user;
            UserService.postSaveTicket(this.ticket).then(
                response => {
                    console.log(response)
                    this.msgError = response
                    this.types = 'success'
                    this.isShow = true
                    this.success = true
                },
                error => {
                    this.content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.msgError = error.response.data
                    console.log(error.response.data)
                    this.types = 'error'
                    this.isShow = true
                    this.success = false
                }
            );
        },
        closeAlert() {
            if (this.success) {
                this.isShow = false
                this.$router.push("/tickets");
            } else {
                this.isShow = false
            }

        }
    }
}
</script>