<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="8" xs="0" sm="0" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-row no-gutters style="flex-wrap: nowrap;">
                                    <v-col cols="2" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0"></v-col>
                                    <v-col cols="8" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                                        <v-select v-model="exchange" :items="itemsExchange" item-text="exchange"
                                            item-value="id" label="Select Exchange" @change="onChange($event)"
                                            persistent-hint return-object single-line></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-row no-gutters style="flex-wrap: nowrap;">
                                <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                                <v-col cols="8" xs="0" sm="0" md="8" class="flex-grow-0 flex-shrink-0">
                                    <v-data-table :headers="headers" :items="items" :search="search"></v-data-table>
                                </v-col>
                            </v-row>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                        <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                            <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                            <v-col cols="10" xs="8" sm="8" md="4">
                                <v-alert prominent type="error">
                                    <v-row>
                                        <v-col class="grow">
                                            {{ msgError }}
                                        </v-col>
                                        <v-col class="shrink">
                                            <v-btn @click="closeAlert">OK</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>

            <!-- <v-sheet v-for="n in 1" :key="n" class="ma-2 pa-2" outlined shaped>
                <v-card elevation="2" outlined shaped>
                    <v-row no-gutters>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="8">
                            <v-card elevation="2" class="d-flex justify-center md-12" outlined>
                                <v-card-title>{{ title }}</v-card-title>
                                <v-divider class="mx-4"></v-divider>

                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="2">
                        </v-col>
                        <v-col cols="8">
                            <v-card class="pa-2">
                                <v-row no-gutters style="flex-wrap: nowrap;">
                                    <v-col cols="2" class="flex-grow-0 flex-shrink-0">
                                    </v-col>
                                    <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                                        <v-select v-model="exchange" :items="itemsExchange" item-text="exchange"
                                            item-value="id" label="Select Exchange" @change="onChange($event)"
                                            persistent-hint return-object single-line></v-select>
                                    </v-col>
                                </v-row>

                                <v-row no-gutters style="flex-wrap: nowrap;">
                                    <v-col cols="2" class="flex-grow-0 flex-shrink-0">
                                    </v-col>
                                    <v-col cols="8" class="flex-grow-0 flex-shrink-0">
                                        <v-data-table :headers="headers" :items="items" :search="search"></v-data-table>
                                    </v-col>
                                </v-row>

                            </v-card>
                        </v-col>
                    </v-row>
                    <br>
                    <v-divider class="mx-4" horizontal></v-divider>
                    <br>
                    <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                        <v-col cols="3" class="flex-grow-0 flex-shrink-0"></v-col>
                        <v-col cols="6" class="flex-grow-0 flex-shrink-0">
                            <v-alert prominent type="error">
                                <v-row>
                                    <v-col class="grow">
                                        {{ msgError }}
                                    </v-col>
                                    <v-col class="shrink">
                                        <v-btn @click="closeAlert">OK</v-btn>
                                    </v-col>
                                </v-row>
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card>
            </v-sheet> -->
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';

export default {
    name: 'WalletComponent',
    components: {
    },
    data() {
        return {
            idBot: '',
            title: "WALLETS",
            // Table
            headers: [
                { text: "Divisa", value: "currency", align: "center" },
                { text: "Disponible", value: "available", align: "center" },
                { text: "Congelado", value: "frozen", align: "center" },
                { text: "Total", value: "total", align: "center" },
                { text: "Retirando", value: "withdrawing", align: "center" }
            ],
            items: [],
            itemsExchange: [],
            exchange: '',
            right: null,
            search: '',
            start: false,
            colors: "success",
            msgBtn: '',
            isShow: false,
            msgError: '',
            apiError: false
        };
    },
    computed: {},
    created() {
    },
    mounted() {
        UserService.getClients(this.$store.state.auth.user.email).then(
            response => {
                if (response.data !== '') {
                    // console.log(response.data)
                    // this.desserts = response.data;
                    for (let i = 0; i < response.data.length; i++) {
                        let aux = { 'id': response.data[i]['id'], 'exchange': response.data[i]['exchange']['exchange'] }
                        this.itemsExchange.push(aux);
                    }
                }
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    },
    methods: {
        onChange(exchange) {
            console.log(exchange.id)
            UserService.getWallets(this.$store.state.auth.user.email, exchange.id).then(
                response => {
                    this.loadData(response.data);
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.msgError = error.response.data
                    this.isShow = true
                    this.apiError = true
                }
            );
        },
        loadData(data) {
            this.items = data;
        },
        closeAlert() {
            this.isShow = false
        }
    }
}
</script>