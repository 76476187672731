<template>
    <SuccessComponent />
</template>
      
<script>
import SuccessComponent from '../../components/user/SuccessComponent.vue'

export default {
    name: 'SuccessView',
    components: {
        SuccessComponent,
    },
}
</script>